* {
    margin: 0;
    padding: 0; 
}
html, body {
    overflow-x: hidden; 
    /* visibility: hidden; */
}
main{
    --lg-1-1: #A8F9EF;
    --lg-1-2: #295643;

    --lg-2-1: #E4A8F9;
    --lg-2-2: #642F5E;

    --lg-3-1: #A0A3ED;
    --lg-3-2: #589CDB;

    --lg-4-1: #E19696;
    --lg-4-2: #8B2929;

    --lg-5-1: #DB8058;
    --lg-5-2: #EDCEA0;

    --lg-1-b1: rgba(168, 249, 239, 0.2);
    --lg-2-b1: rgba(228, 168, 249, 0.2);
    --lg-3-b1: rgba(160, 163, 237, 0.2);
    --lg-4-b1: rgba(225, 150, 150, 0.2);
    --lg-5-b1: rgba(219, 128, 88, 0.2);

    --in-house-font: 18px;
    --expertise-font: 34px;
    --across-globe-font-size: 40px;
    --equip-op-font-size: 18px;

    --abbre-font-size: 250px;
    --abbre-full-form: 35px;
}
.txt-alg-cen{text-align: center;}
.txt-alg-lft{text-align: left;}
.txt-alg-rgt{text-align: left;}
.relative-container{
    position: relative;
}
.readable-bodyCopy{
    line-height: 200%;
}
.webgl {
    outline: none;
    z-index: -1;
    background-color: var(--primary-2);
    background-color: #e5e5f7;
    /* opacity: 0.8; */
    /* background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 10px ), repeating-linear-gradient( #444cf755, #444cf7 ); */
    /* background-image: url('../images/index/index_bg.png'); */
    /* object-fit: contain; */
    /* background-repeat: no-repeat; */
}

.black-text-w-shadow{
    color: var(--white);
    text-shadow: -2px 2px 10px #00000050;
}
.overlaying-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
}
.centerer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}
.sub-hero{
    margin-top: calc(var(--spacer-eLarge)* 4);
    margin-bottom: calc(var(--spacer-eLarge)* 2);
}
.sub-hero > h1{
    text-transform: lowercase;
}

/* SECTION 2 */
.products-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: calc(var(--spacer-eLarge)* 2);
}
.product-item{
    box-sizing: border-box;
    margin-bottom: var(--spacer-eLarge);
    flex: 1;
    width: 100%;
    background-color: var(--background-2);
    padding: var(--spacer-eLarge);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}
    .static-h{box-shadow: -2px 2px 12px var(--lg-1-b1);
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .jet-h{box-shadow: -2px 2px 12px var(--lg-2-b1);
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .rotatory-h{box-shadow: -2px 2px 12px var(--lg-3-b1);
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .steam-h{box-shadow: -2px 2px 12px var(--lg-4-b1);
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .hybrid-h{box-shadow: -2px 2px 12px var(--lg-5-b1);
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}

    .static-h>h1{background: -webkit-linear-gradient( 225deg, var(--lg-1-1), var(--lg-1-2));}
    .jet-h>h1{background: -webkit-linear-gradient( 45deg, var(--lg-2-1), var(--lg-2-2));}
    .rotatory-h>h1{background: -webkit-linear-gradient( 225deg, var(--lg-3-1), var(--lg-3-2));}
    .steam-h>h1{background: -webkit-linear-gradient( 45deg, var(--lg-4-1), var(--lg-4-2));}
    .hybrid-h>h1{background: -webkit-linear-gradient( 45deg, var(--lg-5-1), var(--lg-5-2));}

.product-item > h1 {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: var(--spacer-medium);
}
.alg-itm-end{align-items: flex-start;}
.alg-itm-ctr{align-items: flex-start;}
.click-more-info-div {
    display: flex;
    flex-direction: row;
    opacity: 0;
}
.flex-row-reverse{flex-direction:row; justify-content: flex-end;}
.click-more-info-text{
    opacity: 0.4;
    font-size: 16px;
    margin: 0 var(--spacer-medium);
    transition: var(--transition-inactive);
}
.product-item:hover .click-more-info-div, .product-item:active .click-more-info-div{
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

/* SECTION 3 */
.section-3{
    margin-bottom: var(--spacer-eLarge);
}
.sec3-txt{margin: 0 auto var(--spacer-eLarge); max-width: 700px;}
.sec3-title{
    opacity: 0.4; 
    margin: calc(var(--spacer-eLarge)*3) 0 calc(var(--spacer-eLarge)*2);
    font-size: var(--in-house-font);
}
.expertise-container{
    height: 80px; 
    overflow: hidden;
    margin-bottom: calc(var(--spacer-eLarge)*4);
}
.expertise-list > li > h1{
    font-family: 'Gilroy-SemiBold';
    text-transform: lowercase;
    color: var(--text);
    font-size: var(--expertise-font);
    opacity: 1;
    font-style: bold;
}
.expertise-list > li {
    height: 80px;
    margin-bottom: var(--spacer-eLarge);
}

/* Section 4 */
.section-4{
    margin-bottom: calc(var(--spacer-eLarge)*4);
    position: relative;
}
.sphere-webgl {
    position: absolute;
}
.sec4-container{
    position: relative;
    max-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.sec4-txt-part{
    margin: 0 auto;
}
.sec4-title{
    line-height: 200%;
    font-size: var(--equip-op-font-size);
    font-weight: lighter;
}
.across-globe-txt{
    font-size: var(--across-globe-font-size);
}


/* Section 5 */
.list-conatiners{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: calc(var(--spacer-eLarge)*2);
}
.list-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--background-2);
    border-radius: 20px;
    min-width: 400px;
    overflow: hidden;
    margin: var(--spacer-eLarge);
    box-shadow: -4px 4px 20px #000000;
}
.list-text{
    padding: var(--spacer-eLarge) calc(var(--spacer-eLarge)*2) 0 var(--spacer-eLarge);
}
.list-text > h2 {
    margin-bottom: var(--spacer-eLarge);
}
.list-text > ul {
    list-style: square;
}
.list-text > ul > li {
    margin-bottom: var(--spacer-large);
    margin-left: var(--spacer-eLarge);
}
.see-full-list-container{
    margin-top: var(--spacer-large);
    background-color: var(--primary-1);
    width: 100%;
    transition: var(--transition-inactive)
}
.see-full-list-container:hover, .see-full-list-container:active{
    background-color: var(--primary-1-focus);
    transition: var(--transition-active)
}
.see-full-list-container > p{
    margin: var(--spacer-large);
    text-align: center;
}

/* Section 6 */
.sec6-container{
    box-sizing: border-box;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(var(--spacer-eLarge)* 5);
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}
.abbreviation{
    font-family: 'Gilroy-Medium';
    font-size: var(--abbre-font-size);
    opacity: 1;
    mix-blend-mode: overlay;
    line-height: 80%;
    text-shadow: inset 0px 0px 10px #000000;
    width: 202px;
    /* font-variant-numeric: tabular-nums lining-nums; */
    /* font-family: monospace; */
    /* transform: skew(1turn, -20deg); */
    /* text-shadow: 0 0 20px #000000, 0 0 0 black; */
}
.m-2 {
    direction: ltr;
}
.overlaying-text-container{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.overlaying-text-container > p {
    font-size: var(--abbre-full-form);
    text-shadow: 0px 2px 20px #000000;
}
.translate-positive-x{transform: translateX(-40%);}
.translate-negative-x{transform: translateX(-40%);}



/* Section 7 */
.sec7-title{
    text-align: center;
    text-transform: lowercase;
    opacity: 0.8;
    margin-bottom: var(--spacer-eLarge);
}
.images-container{
    flex: content;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}
.images-container > img {
    display: block;
    margin: var(--spacer-large) var(--spacer-medium) var(--spacer-eLarge) 0;
}
.sec7-disclaimer{
    margin: 0 auto;
    text-align: center;
    max-width: 600px;
}
.section-7 > div > h2 {
    margin-left: var(--spacer-large);
}
@media screen and (min-width: 720px) {
    main{
        --in-house-font: 24px;
        --expertise-font: 60px;
        --equip-op-font-size: 30px;
        --across-globe-font-size: 60px;

        --abbre-font-size: 300px;
        --abbre-full-form: 60px;
    }
    .alg-itm-end{align-items: flex-end;}
    .alg-itm-ctr{align-items: center;}
    .txt-alg-rgt{text-align: right;}
    .products-container{
        flex-direction: row;
        align-items: center;
    }
    .product-item{
        background-color: var(--background);
        max-width: 500px;
        min-width: 400px;
        margin-right: var(--spacer-large);
        margin-left: var(--spacer-large);
        border-radius: 16px;
    }

    .flex-row-reverse{flex-direction:row-reverse; justify-content: flex-end;}
    .sec3-title{font-size: var(--in-house-font);}
    .m-2 {direction: rtl;}
    .translate-positive-x{transform: translateX(30%);}
    .translate-negative-x{transform: translateX(-30%);}
    .abbreviation {
        width: 245px;
    }
}
@media screen and (min-width: 1024px) {
    main{
        --across-globe-font-size: 70px;
    }

    .static-h{box-shadow: none;
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .jet-h{box-shadow: none;
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .rotatory-h{box-shadow: none;
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .steam-h{box-shadow: none;
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .hybrid-h{box-shadow: none;
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}

    .static-h:hover, .static-h:active{box-shadow: -2px 2px 12px var(--lg-1-1);
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .jet-h:hover, .jet-h:active{box-shadow: -2px 2px 12px var(--lg-2-1);
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .rotatory-h:hover, .rotatory-h:active{box-shadow: -2px 2px 12px var(--lg-3-1);
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .steam-h:hover, .steam-h:active{box-shadow: -2px 2px 12px var(--lg-4-1);
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}
    .hybrid-h:hover, .hybrid-h:active{box-shadow: -2px 2px 12px var(--lg-5-1);
        transition: all 400ms cubic-bezier(0.885, 0.360, 0.415, 0.840);}

    
    .section-7 > div > h2 {
        margin-left: 0;
    }
}
