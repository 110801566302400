*{
    box-sizing: border-box;
}
body{
    background-color: var(--background);
}
h1, h2, h3, h4, p, .bodyCopy1, .bodyCopy2, .bodyCopy3, .bodyCopy4, a {
    color: var(--text);
    line-height: 120%;
    text-rendering: optimizeLegibility;
}
h1{
    font-family: 'Gilroy-SemiBold';
    font-size: var(--heading-1-SB);
}
h2 {
    font-family: 'Gilroy-SemiBold';
    font-size: var(--heading-2-SB);
    opacity: 60%;
    text-transform: lowercase;
    /* text-transform: capitalize; */
}
h3 {
    font-family: 'Gilroy-SemiBold';
    font-size: var(--heading-3-SB);
}
.bodyCopy1{
    font-family: 'Gilroy-Medium';
    font-size: var(--bodyCopy-1-M);
}
.bodyCopy2{
    font-family: 'Gilroy-Medium';
    font-size: var(--bodyCopy-2-M);
}
.bodyCopy3{
    font-family: 'Gilroy-Regular';
    font-size: var(--bodyCopy-3-R);
}
.bodyCopy4{
    font-family: 'Gilroy-Regular';
    font-size: var(--bodyCopy-4-R);
}
.lineHeight-auto {
    line-height: 120%;
}
.lineHeight-big {
    line-height: var(--lineheight-big);
}
.lineHeight-eLarge {
    line-height: var(--lineheight-eLarge);
}
.lineHeight-xxLarge {
    line-height: var(--lineheight-xxLarge);
}

.superscript {
    vertical-align: super;
}
.subscript {
    vertical-align: sub;
}

img, svg {
    display: block
}
label{
    display: inline-block;
    font-family: 'Gilroy-Medium';
    font-size: var(--bodyCopy-2-M);
    color: var(--text);
    margin-bottom: var(--spacer-mediumSmall);
    opacity: 60%;
}
.mediumButton {
    background-color: var(--primary-1);
    padding: var(--padding-mediumButton);
    /* width: 100%; */
    /* max-width: 420px; */
    border-radius: var(--borderRadius-medium);
    color: var(--text);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
 }
.mediumButton:hover{
     background-color: var(--primary-1-focus);
 }
.largeButton {
    background-color: var(--primary-1);
    padding: var(--padding-largeButton);
    width: 100%;
    max-width: 480px;
    border-radius: var(--borderRadius-large);
    color: var(--text);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: var(--spacer-mediumSmall);
 }
 /* .largeButton:hover{
    background-color: var(--primary-1-focus);
 } */
 .icon{
    margin-left: var(--spacer-mediumSmall);
 }
 .largeButtonIcon{
    display: inline-block;
 }
 .largeButtonText{
    margin-left: var(--spacer-medium);
    text-align: left;
}
.fullOpacity{
    opacity: 100%;
}
/* Header */
nav {
    width: 100%;
    /* background-color: var(--background); */
    background-color: #00000000;
    /* backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px); */
}
.nav-width {
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px 12px 10px 12px;
}
.horizontalLinks > ul {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}
.horizontalLinks > ul > li {
    display: block;
    font-family: 'Gilroy-Medium';
    font-size: var(--bodyCopy-2-M);
    color: var(--text);
    margin-left: var(--spacer-large);
}
.mobileTabletLogo {
    display: visible;
}
.desktopLogo {
    display: none;
}
/* MAIN */
.mainMaxWCenter{
    /* Min spacer to be applied from left and right for mobiles and tablets */
    padding: 0px 18px 0px 12px;
    max-width: var(--max-width);
    margin: auto;
}
.applyMinHeight{
    min-height: calc(100vh - 154px);
}
.mediumFullWidth{
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
}
.mediumLargeFullWidth{
    max-width: 1154px;
    margin-left: auto;
    margin-right: auto;
}
.textHeavyFullWidth{
    max-width: 680px;
    margin: auto
}
.center-text {
    display: block;
    text-align: center;
    margin-left: var(--spacer-large);
    margin-right: var(--spacer-large);
}
.inLine-link{
    color: #0C80C4;
    font-style: italic;
    font-weight: bold;
    text-underline-offset: 6px;
    text-decoration-thickness: 1px;
}
.inLine-info-green{
    display: inline;
    font-style: italic;
    background: -webkit-linear-gradient( 45deg, #7DD2AF, #008F87);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.a-disabled{
    pointer-events: none;
    cursor: default;
}
.subHeading-div{
    max-width: 700px;
    margin-bottom: calc(var(--spacer-eLarge)*2);
}
@media screen and (min-width: 720px) {
    .nav-width{
        padding: var(--spacer-eLarge) 24px var(--spacer-large) 20px;
    }
    .mainMaxWCenter{
        /* Min spacer to be applied from left and right for mobiles and tablets */
        padding: 0px 24px 0px 20px;
    }
}
@media screen and (min-width: 1024px) {
    .mobileTabletLogo {
        display: none;
    }
    .desktopLogo {
        display: block;
    }
}
.link {
    cursor: pointer;
    position: relative;
}
.link::before,
.link::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 100%;
    left: 0;
    pointer-events: none;
}
.link::before {
    content: '';
    /* show by default */
}
.link--metis::before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
}
.link--metis:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
}

/* Hamburger */
.horizontalLinks{
    display: none;
}
/* burger menu animation */
.ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
.hamRotate.active {
    transform: rotate(45deg);
}
.hamRotate180.active {
    transform: rotate(180deg);
}
.line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: var(--text);
    /* stroke: white; */
    stroke-width:5.5;
    stroke-linecap:round;
}
.ham1 .top {
    stroke-dasharray: 40 139;
}
.ham1 .bottom {
    stroke-dasharray: 40 180;
}
.ham1.active .top {
    stroke-dashoffset: -98px;
}
.ham1.active .bottom {
    stroke-dashoffset: -138px;
}
@media screen and (min-width: 720px) {
    .mobileLinks{
        display: none;
    }
    .horizontalLinks {
        display: block;
    }
}

/* Slider Menu */
.slider-menu {
    z-index: 9;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    left: 100%;
    opacity: 0.3;
    transition: left 0.25s ease-in-out, opacity 0.25s ease-in-out;
}
.slider-menu > ul > li {
    color: var(--text);
    display: block;
    font-family: 'Gilroy-Medium';
    font-size: var(--bodyCopy-2-M);
    text-align: right;
    line-height: var(--lineheight-auto);
    margin-bottom: var(--spacer-large);
}
.slider-menu-animation {
    left: 0%;
    opacity: 1;
}
.slider-menu > ul {
    margin-top: var(--spacer-eLarge);
    margin-right: 52px;
}

/* Dropdown menu */
.dropdown {
    display: inline-block;
}
.dropdown-content {
    display: none;
    padding-top: var(--spacer-large);
    width: 272px;
}
.dropdown:hover .dropdown-content {display: block;}

.productMenu{
    background-color: var(--background-2);
    border-radius: var(--borderRadius-large);
    padding: 0 0px 4px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: block;
    max-width: 375px;
    z-index: 5;
    box-shadow: -6px 6px 20px #000000;
    /* box-shadow: -6px 6px 20px var(--background); */
}
.productMenuHeading{
    display: inline-block;
    text-align: left;
    margin-left: 16px;
    margin-top: 12px;
    padding-right: var(--spacer-medium);
    margin-bottom: var(--spacer-mediumSmall);
    opacity: 40%;
}
.inLineMenuLinkBlock{
    position: relative; /* Width animation */
    display: inline-block;
    width: 100%;
    border-radius: var(--borderRadius-small);
}
/* https://stackoverflow.com/a/18647217/12268569 */

.inLineMenuLinkBlock:after { /* Width animation */
    content: '\A';
    position: absolute;
    top: 0;
    background-color: var(--primary-1);
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 0%; /* Specify the width.. */
    transition: all 0.2s ease-in;
}

.inLineMenuLinkBlock:hover:after{ /* Width animation */
    background-color: var(--primary-1-focus);
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.inLineMenuLinkBlock > p{
    line-height: var(--lineheight-auto);
    text-align: right;
    margin: var(--padding-inlineMenu);
}

.whitePagesMenu{
    background-color: var(--background-2);
    border-radius: var(--borderRadius-large);
    padding: var(--spacer-medium) 0px 4px;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    display: block;
    max-width: 375px;
    z-index: 5;
    box-shadow: -6px 6px 20px #000000;
    /* box-shadow: -6px 6px 20px var(--background); */
}
@media screen and (min-width: 720px) {
    .dropdown {
        position: relative;
    }
    .dropdown-content {
        position: absolute;
        right: 0;
        z-index: 1;
        width: 370px;
    }
    .productMenuHeading, .whitePagesMenu{
        text-align: right;
    }
}


/* Main Content */
.spacedHeading1{
    display: inline-block;
    margin-top: var(--spacer-eLarge);
    margin-bottom: var(--spacer-eLarge);
}

/* Footer */
footer {
    margin-top: calc(var(--spacer-eLarge)*2);
    padding: var(--padding-largeButton);
    margin-bottom: var(--spacer-eLarge);
}
footer > p{
    text-align: center;
    margin-top: 6px;
}
.footer-svg > svg{
    margin-left: auto;
    margin-right: auto;
}