/* https://transfonter.org */
@font-face {
    font-family: 'Gilroy-SemiBold';
    src: url('../fonts/Gilroy-SemiBold.woff2') format('woff2');
}
@font-face {
    font-family: 'Gilroy-Medium';
    src: url('../fonts/Gilroy-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'Gilroy-Regular';
    src: url('../fonts/Gilroy-Regular.woff2') format('woff2');
}
:root{
    /* COLORS */
    --background: #0D0E11;
    /* --background: #FFFFFF; */
    --background-nav: #0D0E1170;
    --background-2: #16181F;
    --text: #EAEAEA;
    /* --text: #000000; */
    --primary-1: #004196;
    --primary-1-focus: #023271;
    --primary-2: #830D25;
    --black: #000000;
    --white: #FFFFFF;

    /* BREAK POINTS */
    --bp-mobile: 375px;
    --bp-tablet: 768px;
    --bp-desktop: 1024px;

    /* FONT SIZES */
    --heading-1-SB: 28px;
    --heading-2-SB: 18px;
    --heading-3-SB: 14px;
    
    --bodyCopy-1-M: 16px;
    --bodyCopy-2-M: 14px;
    /* --bodyCopy-2-M: 16px; */

    --bodyCopy-3-R: 16px;
    --bodyCopy-4-R: 10px;

    /* LINE HEIGHT */
    --lineheight-auto: 120%;
    --lineheight-big: 150%;
    --lineheight-eLarge: 180%;
    --lineheight-xxLarge: 200%;

    /* OPACITY */
    --opacity-default: 0.4;
    

    /* SPACERS */
    --spacer-eLarge: 24px;
    --spacer-large: 16px;
    --spacer-medium: 12px;
    --spacer-mediumSmall: 8px;

    /* BORDER */
    --borderRadius-large: 6px;
    --borderRadius-medium: 4px;
    --borderRadius-small: 2px;

    --borderWidth: 1px; 

    /* PADDING */
    --padding-largeButton: 8px 24px 8px 12px;
    --padding-mediumButton: 8px 12px 8px 10px;
    --padding-smallButton: 4px 8px 4px 8px;

    --padding-accordion: 8px 20px 8px 20px;

    --padding-inlineMenu: 4px 8px 8px 4px;

    --padding-input: 10px 24px 10px 12px;

    /* MAX WIDTH */
    --max-width: 1200px;
    /* --max-width-text: min(65ch, 680px); */

    /* TRANSITIONS */
    --transition-inactive: all .2s ease-out;
    --transition-active: all .1s ease-in;
}

@media screen and (min-width: 720px){
    :root{
        --heading-1-SB: 32px;
        --heading-2-SB: 20px;
        --heading-3-SB: 14px;
        
        --bodyCopy-1-M: 18px;
        --bodyCopy-2-M: 16px;

        --bodyCopy-3-R: 18px;
        --bodyCopy-4-R: 12px;

        --padding-inLineMenu: 6px 8px 6px 8px;
    }
}

@media screen and (min-width: 1024px) {
    :root{
        --heading-1-SB: 40px;
        --heading-2-SB: 24px;
        --heading-3-SB: 14px;
        
        /* --bodyCopy-1-M: 20px; */
        --bodyCopy-1-M: 20px;
        --bodyCopy-2-M: 16px;

        --bodyCopy-3-R: 20px;
        --bodyCopy-4-R: 12px;
    }
}